import { useMutation, useQuery } from '@/plugins/apollo'
import { useServiceHelper } from './service.helper'

import CreatePrequalificationMutation from '@/schemas/Preparation/CreatePrequalification.mutation.gql'
import PrequalificationLink from '@/schemas/Preparation/PrequalificationLink.query.gql'
import PrequalificationsQuery from '@/schemas/Preparation/Prequalifications.query.gql'
import ReloadPrequalificationDetailsMutation from '@/schemas/Preparation/ReloadPrequalificationDetails.mutation.gql'
import UpdatePrequalificationMutation from '@/schemas/Preparation/UpdatePrequalification.mutation.gql'
import ValidateBankRequestPrequalificationQuery from '@/schemas/Project/ValidateBankRequestPrequalification.query.gql'

import ICreatePrequalificationMutation from '@/types/schemas/Preparation/CreatePrequalification.interface'
import IPrequalification from '@/types/Prequalification.interface'
import IPrequalificationLinkQuery from '@/types/schemas/Preparation/PrequalificationLinkQuery.interface'
import IPrequalificationsQuery from '@/types/schemas/Preparation/PrequalificationsQuery.interface'
import IReloadPrequalificationDetailsMutation from '@/types/schemas/Preparation/ReloadPrequalificationDetailsMutation.interface'
import IUpdatePrequalificationMutation from '@/types/schemas/Preparation/UpdatePrequalificationMutation.interface'
import IValidateBankRequestPrequalification from '@/types/schemas/Project/ValidateBankRequestPrequalificationQuery.interface'
import { EResponseType } from '@/types/ResponseType.enum'

const { getActiveRequestId, getProjectId } = useServiceHelper()

const createPrequalification = async (): Promise<
  ICreatePrequalificationMutation['createPrequalification'] | undefined
> => {
  const { data } = await useMutation<ICreatePrequalificationMutation>({
    mutation: CreatePrequalificationMutation,
    variables: {
      projectId: getProjectId(),
      requestId: getActiveRequestId(),
    },
  })

  return data?.createPrequalification
}

const getPrequalificationLink = async (id: string): Promise<string> => {
  const { data } = await useQuery<IPrequalificationLinkQuery>({
    query: PrequalificationLink,
    variables: {
      id,
    },
  })

  return data.prequalificationLink
}

const getPrequalifications = async (): Promise<IPrequalification[]> => {
  const {
    data: { prequalifications },
  } = await useQuery<IPrequalificationsQuery>({
    query: PrequalificationsQuery,
    variables: {
      id: getProjectId(),
    },
  })

  return prequalifications
}

const reloadPrequalificationDetails = async (
  prequalificationId: string,
): Promise<IReloadPrequalificationDetailsMutation['reloadPrequalificationDetails'] | undefined> => {
  const { data } = await useMutation<IReloadPrequalificationDetailsMutation>({
    mutation: ReloadPrequalificationDetailsMutation,
    variables: {
      prequalificationId,
      requestId: getActiveRequestId(),
    },
  })

  return data?.reloadPrequalificationDetails
}

interface IUpdatePrequalificationInput {
  prequalificationId: string
  bankResponse: EResponseType | null
  bankResponseRate: number
  bankResponseDuration: number
  bankDeniedReason: string | null
  synthesis: string | null
}

const updatePrequalification = async (
  variables: IUpdatePrequalificationInput,
): Promise<IUpdatePrequalificationMutation['updatePrequalification'] | undefined> => {
  const { data } = await useMutation<IUpdatePrequalificationMutation>({
    mutation: UpdatePrequalificationMutation,
    variables,
  })

  return data?.updatePrequalification
}

const validateBankRequestPrequalification = async (
  prequalificationId: string,
): Promise<IValidateBankRequestPrequalification['validateBankRequestPrequalification']> => {
  const { data } = await useQuery<IValidateBankRequestPrequalification>({
    query: ValidateBankRequestPrequalificationQuery,
    variables: {
      id: prequalificationId,
    },
  })

  return data.validateBankRequestPrequalification
}

export const usePrequalificationService = () => ({
  createPrequalification,
  getPrequalificationLink,
  getPrequalifications,
  reloadPrequalificationDetails,
  updatePrequalification,
  validateBankRequestPrequalification,
})
