import { useQuery } from '@/plugins/apollo'
import { useServiceHelper } from './service.helper'

import BankAccountsQuery from '@/schemas/Preparation/BankAccounts.query.gql'

import IBankAccount from '@/types/BankAccount.interface'
import IBankAccountsQuery from '@/types/schemas/Preparation/BankAccountsQuery.interface'

const { getProjectId } = useServiceHelper()

const getBankAccounts = async (bankSlug: string): Promise<IBankAccount[]> => {
  const {
    data: { bankAccounts },
  } = await useQuery<IBankAccountsQuery>({
    query: BankAccountsQuery,
    variables: {
      bankSlug,
      id: getProjectId(),
    },
  })

  return bankAccounts
}

export const useBankAccountService = () => ({
  getBankAccounts,
})
