import { createApp, Plugin } from 'vue'
import App from '@/App.vue'
import router from '@/router'
import i18n from '@/config/i18n'
import { createPinia } from 'pinia'

import '@/assets/style.css'
import 'flowbite'
import 'mosha-vue-toastify/dist/style.css'
import 'flag-icons/css/flag-icons.css'

import { auth0 } from './auth'

import { createApolloClient } from '@/plugins/apollo'

import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import duration from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'
import 'dayjs/locale/fr'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import isToday from 'dayjs/plugin/isToday'
import isYesterday from 'dayjs/plugin/isYesterday'
import { initSentry } from '@/config/sentry'
import { createEvents } from '@/config/tracking'

dayjs.extend(customParseFormat)
dayjs.extend(duration)
dayjs.extend(relativeTime)
dayjs.locale('fr')
dayjs.extend(localizedFormat)
dayjs.extend(isToday)
dayjs.extend(isYesterday)

const app = createApp(App)

if (process.env.NODE_ENV === 'production') {
  initSentry(app)
}

app.use(router)
app.use(createPinia())
app.use(i18n)
app.use(createEvents())
app.use(auth0 as unknown as Plugin) // apparently Auth0Plugin and Plugin are not compatible but it's working fine
;(async () => {
  const token = await auth0.getAccessTokenSilently()
  createApolloClient(token)

  app.mount('#app')
})()
