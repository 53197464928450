import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  concat,
  NormalizedCacheObject,
  QueryOptions,
  ApolloError,
  MutationOptions,
  FetchResult,
} from '@apollo/client/core'

import { DocumentNode, GraphQLError } from 'graphql'

export let apolloClient: ApolloClient<NormalizedCacheObject>

export async function createApolloClient(token: string): Promise<void> {
  if (!apolloClient) {
    // Apollo conf
    const httpLink = new HttpLink({
      uri: `${process.env.VUE_APP_API_HOST}/graphql_admin`,
    })
    const middlewareLink = new ApolloLink((operation, forward) => {
      operation.setContext(() => {
        return {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      })

      return forward(operation)
    })

    const cache = new InMemoryCache({
      addTypename: false,
      typePolicies: {
        Query: {
          fields: {
            project: {
              merge: true,
            },
          },
        },
      },
    })

    const link = concat(middlewareLink, httpLink)

    apolloClient = new ApolloClient({
      link,
      cache,
      connectToDevTools: true,
    })
  }
}

interface ICustomApolloQueryResult<TData> {
  data: TData
  loading: boolean
  error?: ApolloError
  errors?: readonly GraphQLError[]
}

export async function useQuery<TData>(options: QueryOptions): Promise<ICustomApolloQueryResult<TData>> {
  const { data, loading, errors, error } = await apolloClient.query<TData>({
    fetchPolicy: 'no-cache',
    ...options,
  })

  return { data, loading, error, errors }
}

export function useMutation<TData>(options: MutationOptions): Promise<FetchResult<TData>> {
  return apolloClient.mutate(options)
}

export async function useRefetchQuery<TData>(query: string | DocumentNode): Promise<ICustomApolloQueryResult<TData>> {
  const [response] = await apolloClient.refetchQueries({
    include: [query],
  })

  return response
}
