import { useMutation, useQuery } from '@/plugins/apollo'
import { useServiceHelper } from './service.helper'

import BankContactsQuery from '@/schemas/Preparation/BankContacts.query.gql'
import InsertBankContactMutation from '@/schemas/Preparation/InsertBankContact.mutation.gql'
import UpdateBankContactMutation from '@/schemas/Preparation/UpdateBankContact.mutation.gql'

import IBankContact from '@/types/BankContact.interface'
import IBankContactsQuery from '@/types/schemas/Preparation/BankContactsQuery.interface'
import IInsertBankContactMutation from '@/types/schemas/Preparation/InsertBankContactMutation.interface'
import INewContact from '@/types/NewContact.interface'
import IUpdateBankContactMutation from '@/types/schemas/Preparation/UpdateBankContactMutation.interface'

const { getProjectId } = useServiceHelper()

const getBankContacts = async (bankSlug: string): Promise<IBankContact[]> => {
  const {
    data: { contacts },
  } = await useQuery<IBankContactsQuery>({
    query: BankContactsQuery,
    variables: {
      id: getProjectId(),
      bankSlug,
    },
  })

  return contacts
}

const insertBankContact = async (
  contact: INewContact,
): Promise<IInsertBankContactMutation['insertBankContact'] | undefined> => {
  const { data } = await useMutation<IInsertBankContactMutation>({
    mutation: InsertBankContactMutation,
    variables: contact,
  })

  return data?.insertBankContact
}

const updateBankContact = async (
  contactId: string,
  comment: string,
): Promise<IUpdateBankContactMutation['updateBankContact'] | undefined> => {
  const { data } = await useMutation<IUpdateBankContactMutation>({
    mutation: UpdateBankContactMutation,
    variables: {
      contactId: contactId,
      comment: comment,
    },
  })

  return data?.updateBankContact
}

export const useBankContactService = () => ({
  getBankContacts,
  insertBankContact,
  updateBankContact,
})
