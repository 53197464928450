import { toRaw } from 'vue'
import { reactive, watch } from 'vue'

const STORAGE_KEY = 'feature_flags'

export enum EFeatureFlag {
  _ = 'test', // keep this so enum is not empty, it is filtered out anyway
  BankStatementsView = 'bank_statements_view',
}

export interface IFeatureFlag {
  name: EFeatureFlag
  value: boolean
}

let storedFeatureFlags: IFeatureFlag[] = []
const storedItem = localStorage.getItem(STORAGE_KEY)

if (storedItem) {
  storedFeatureFlags = JSON.parse(storedItem) as IFeatureFlag[]
}

const featureFlags = reactive<IFeatureFlag[]>(
  Object.entries(EFeatureFlag).map(([, flag]) => ({
    name: flag,
    value: storedFeatureFlags.find(({ name }) => name === flag)?.value ?? false,
  })),
)

watch(featureFlags, () => {
  localStorage.setItem(STORAGE_KEY, JSON.stringify(toRaw(featureFlags)))
})

export const useFeatureFlags = () => ({
  featureFlags,
})
