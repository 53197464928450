import { AnalyticsBrowser } from '@segment/analytics-next'
import { computed } from 'vue'
import segmentConfig from '@/config/segment.json'
import { storeToRefs } from 'pinia'
import { useFeatureFlags } from '@/config/composables/feature_flags'
import { useStore } from './store'

import { EEventType, IEvent } from '@/config/tracking'

const analytics = AnalyticsBrowser.load({ writeKey: segmentConfig.write_key })

const { featureFlags } = useFeatureFlags()
const enabledFeatureFlags = computed<Record<string, true>>(() =>
  featureFlags.reduce<Record<string, true>>((list, flag) => {
    if (flag.value) {
      list[flag.name] = flag.value
    }

    return list
  }, {}),
)

const trackEvent = (trackedEvent: IEvent, props?: Record<string, string | string[] | undefined>): void => {
  if (trackedEvent.type !== EEventType.NoTracking) {
    const store = useStore()
    const { projectId } = storeToRefs(store)

    const eventProperties = {
      projectId: projectId.value,
      ...enabledFeatureFlags.value,
      ...props,
    }

    if (process.env.NODE_ENV === 'production') {
      analytics[trackedEvent.type](`${trackedEvent.object}:${trackedEvent.action}`, eventProperties)
    }
  }
}

export const useAnalytics = () => ({
  ...analytics,
  trackEvent,
})
