<template>
  <svg
    role="status"
    class="animate-spin"
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM2.6496 10C2.6496 14.0595 5.94049 17.3504 10 17.3504C14.0595 17.3504 17.3504 14.0595 17.3504 10C17.3504 5.94049 14.0595 2.6496 10 2.6496C5.94049 2.6496 2.6496 5.94049 2.6496 10Z"
      fill="#D1D5DB"
    />
    <path
      d="M20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7362 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761204C12.6136 0.258657 11.3132 -2.1395e-07 10 0L10 2.6496C10.9653 2.6496 11.9211 2.83973 12.8129 3.20912C13.7047 3.57851 14.515 4.11994 15.1975 4.80248C15.8801 5.48503 16.4215 6.29533 16.7909 7.18712C17.1603 8.07892 17.3504 9.03473 17.3504 10H20Z"
      fill="currentColor"
    />
  </svg>
</template>
