import { createAuth0 } from '@auth0/auth0-vue'
import authConfig from '@/config/auth_config.json'

const { domain, client_id, audience } = authConfig

export const auth0 = createAuth0({
  domain,
  clientId: client_id,
  cacheLocation: 'localstorage',
  authorizationParams: {
    redirect_uri: window.location.origin,
    audience,
  },
})
