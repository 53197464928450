import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { useAnalytics } from '@/config/composables/segment'
import { events, IEvent } from '@/config/tracking'
import { useStore } from '@/config/composables/store'
import { storeToRefs } from 'pinia'
import { authGuard } from '@auth0/auth0-vue'

declare module 'vue-router' {
  interface RouteMeta {
    event?: IEvent
  }
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/project/:projectId',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '@/views/AppLayout.vue'),
    props: true,
    children: [
      {
        path: 'requests',
        name: 'requests',
        component: () => import(/* webpackChunkName: "requests" */ '@/views/Requests/RequestsWrapper.vue'),
        redirect: { name: 'grid' },
        children: [
          {
            path: 'grid',
            name: 'grid',
            component: () => import(/* webpackChunkName: "grid" */ '@/views/Preparation/GridView.vue'),
            meta: {
              event: events.gridView,
            },
          },
          {
            path: 'insurance',
            name: 'insurance',
            component: () => import(/* webpackChunkName: "insurance" */ '@/views/Preparation/InsuranceView.vue'),
            meta: {
              event: events.insuranceView,
            },
          },
          {
            path: 'synthesis',
            name: 'synthesis',
            component: () => import(/* webpackChunkName: "synthesis" */ '@/views/Preparation/SynthesisView.vue'),
            meta: {
              event: events.synthesisView,
            },
          },
        ],
      },
      {
        path: 'documents',
        name: 'documents',
        component: () => import(/* webpackChunkName: "documents" */ '@/views/Documents/DocumentsWrapper.vue'),
        redirect: { name: 'documents_categories' },
        children: [
          {
            path: 'categories',
            name: 'documents_categories',
            component: () =>
              import(/* webpackChunkName: "documents" */ '@/components/preparation/documents/DocumentsCategories.vue'),
            meta: {
              event: events.documentsCategoriesView,
            },
          },
          {
            path: 'files',
            name: 'documents_files',
            component: () =>
              import(/* webpackChunkName: "documents" */ '@/components/preparation/documents/DocumentsList.vue'),
            meta: {
              event: events.documentsListView,
            },
          },
          {
            path: 'bank_statements',
            name: 'bank_statements',
            component: () =>
              import(/* webpackChunkName: "bank_statements" */ '@/views/Preparation/BankStatementsView.vue'),
            meta: {
              event: events.bankStatementsView,
            },
          },
        ],
      },
      {
        path: 'interrogations',
        name: 'bank_request',
        component: () =>
          import(/* webpackChunkName: "interrogations" */ '@/views/Interrogations/InterrogationsWrapper.vue'),
        children: [
          {
            path: 'prequalifications',
            name: 'prequalifications',
            component: () =>
              import(/* webpackChunkName: "interrogations" */ '@/views/Preparation/PrequalificationsView.vue'),
            meta: {
              event: events.prequalificationsView,
            },
          },
          {
            path: '',
            name: 'interrogations',
            component: () =>
              import(/* webpackChunkName: "interrogations" */ '@/views/Preparation/InterrogationsView.vue'),
            meta: {
              event: events.interrogationView,
            },
          },
          {
            path: 'agreements',
            name: 'agreements',
            component: () => import(/* webpackChunkName: "agreements" */ '@/views/Preparation/AgreementsView.vue'),
            meta: {
              event: events.agreementView,
            },
          },
        ],
      },
      {
        path: 'certificate',
        name: 'certificate',
        component: () => import(/* webpackChunkName: "certificate" */ '@/views/Preparation/CertificateView.vue'),
        meta: {
          event: events.certificateView,
        },
      },
      {
        path: 'mandate',
        name: 'mandate',
        component: () => import(/* webpackChunkName: "mandate" */ '@/views/Preparation/MandateView.vue'),
        meta: {
          event: events.mandateView,
        },
      },
      {
        path: 'calculators',
        name: 'calculators',
        component: () => import(/* webpackChunkName: "calculators" */ '@/views/Preparation/CalculatorsView.vue'),
        meta: {
          event: events.calculatorView,
        },
      },
    ],
  },
  {
    path: '/client/:projectId',
    name: 'client_view',
    component: () => import(/* webpackChunkName: "client_view" */ '@/views/ClientView.vue'),
    props: true,
    meta: {
      event: events.clientView,
    },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {
        left: 0,
        top: 0,
      }
    }
  },
})

router.beforeEach((to) => {
  const store = useStore()
  const { projectId } = storeToRefs(store)

  const paramsProjectId = to.params.projectId

  if (paramsProjectId && (!projectId.value || projectId.value !== paramsProjectId)) {
    projectId.value = paramsProjectId as string
  }

  authGuard(to)
})

const { trackEvent } = useAnalytics()

router.afterEach((to, from) => {
  if (to.name !== from.name) {
    if (to.meta.event) {
      trackEvent(to.meta.event)
    }
  }
})

export default router
