import { useMutation, useQuery } from '@/plugins/apollo'
import { useStore } from '@/config/composables/store'

import ApproveReviewMutation from '@/schemas/Notifications/ApproveReview.mutation.gql'
import DenyReviewMutation from '@/schemas/Notifications/DenyReview.mutation.gql'
import HandleNotificationMutation from '@/schemas/Notifications/HandleNotification.mutation.gql'
import NotificationsQuery from '@/schemas/Notifications.query.gql'

import TNotification, { TNotificationReview } from '@/types/Notification.type'
import IApproveReviewMutation from '@/types/schemas/ApproveReviewMutation.interface'
import IDenyReviewMutation from '@/types/schemas/DenyReviewMutation.interface'
import IHandleNotificationMutation from '@/types/schemas/HandleNotificationMutation.interface'
import INotificationsQuery from '@/types/schemas/NotificationsQuery.interface'

const getProjectId = (): string | null => {
  const store = useStore()

  return store.projectId
}

const getNotifications = async (): Promise<TNotification[]> => {
  const { data } = await useQuery<INotificationsQuery>({
    query: NotificationsQuery,
    variables: {
      id: getProjectId(),
    },
  })

  return data.notifications
}

const handleNotification = async (notification: TNotification): Promise<void> => {
  const { data } = await useMutation<IHandleNotificationMutation>({
    mutation: HandleNotificationMutation,
    variables: {
      notificationId: notification.id,
    },
  })

  if (data?.handleNotification) {
    if (!data.handleNotification.success && data.handleNotification.error) {
      throw data.handleNotification.error
    }
  }
}

const approveReview = async (notification: TNotificationReview): Promise<void> => {
  const { data } = await useMutation<IApproveReviewMutation>({
    mutation: ApproveReviewMutation,
    variables: {
      targetId: notification.review?.id,
      notificationId: notification.id,
    },
  })

  if (data?.approveReview && data.handleNotification) {
    if (
      (!data.approveReview.success && data.approveReview.error) ||
      (!data.handleNotification.success && data.handleNotification.error)
    ) {
      throw data.handleNotification.error ?? data.approveReview.error
    }
  }
}

const denyReview = async (notification: TNotificationReview): Promise<void> => {
  const { data } = await useMutation<IDenyReviewMutation>({
    mutation: DenyReviewMutation,
    variables: {
      targetId: notification.review.id,
      notificationId: notification.id,
    },
  })

  if (data?.denyReview && data.handleNotification) {
    if (
      (!data.denyReview.success && data.denyReview.error) ||
      (!data.handleNotification.success && data.handleNotification.error)
    ) {
      throw data.handleNotification.error ?? data.denyReview.error
    }
  }
}

export const useNotificationService = () => ({
  approveReview,
  denyReview,
  getNotifications,
  handleNotification,
})
