import { useQuery } from '@/plugins/apollo'
import { useServiceHelper } from './service.helper'

import GlobalDataQuery from '@/schemas/GlobalData.query.gql'
import IncomesCalculationRecommendationQuery from '@/schemas/IncomesCalculationRecommendation.query.gql'
import InitialGlobalDataQuery from '@/schemas/InitialGlobalData.query.gql'
import ScoringQuery from '@/schemas/Scoring.query.gql'

import IGlobalDataQuery from '@/types/schemas/GlobalDataQuery.interface.ts'
import IIncomesCalculationRecommendation from '@/types/IncomesCalculationRecommendation.interface'
import IIncomesCalculationRecommendationQuery from '@/types/schemas/IncomesCalculationRecommendationQuery.interface'
import IInitialGlobalDataQuery from '@/types/schemas/InitialGlobalDataQuery.interface'
import IScoring from '@/types/Scoring.interface'
import IScoringQuery from '@/types/schemas/ScoringQuery.interface'

const { getActiveRequestId, getProjectId } = useServiceHelper()

const getInitialGlobalData = async (): Promise<IInitialGlobalDataQuery> => {
  const { data } = await useQuery<IInitialGlobalDataQuery>({
    query: InitialGlobalDataQuery,
    variables: {
      id: getProjectId(),
    },
  })

  return data
}

const getGlobalData = async (): Promise<IGlobalDataQuery> => {
  const { data } = await useQuery<IGlobalDataQuery>({
    query: GlobalDataQuery,
    variables: {
      id: getProjectId(),
      requestId: getActiveRequestId(),
    },
  })

  return data
}

const getIncomesCalculationRecommendation = async (): Promise<IIncomesCalculationRecommendation[]> => {
  const { data } = await useQuery<IIncomesCalculationRecommendationQuery>({
    query: IncomesCalculationRecommendationQuery,
    variables: {
      id: getProjectId(),
      requestId: getActiveRequestId(),
    },
  })

  return data.incomesCalculationRecommendation
}

const getScoring = async (): Promise<IScoring | null> => {
  const {
    data: { scoring },
  } = await useQuery<IScoringQuery>({
    query: ScoringQuery,
    variables: {
      id: getProjectId(),
      requestId: getActiveRequestId(),
    },
  })

  return scoring
}

export const useGlobalDataService = () => ({
  getGlobalData,
  getIncomesCalculationRecommendation,
  getInitialGlobalData,
  getScoring,
})
