import { useMutation, useQuery } from '@/plugins/apollo'
import { useStore } from '@/config/composables/store'

import BankStatementsAnalysisQuery from '@/schemas/Preparation/BankStatementsAnalysis.query.gql'
import TriggerBankStatementsAnalysisQuery from '@/schemas/Preparation/TriggerBankStatementsAnalysis.query.gql'
import UpdateBankStatementsAnalysisCollectionMutation from '@/schemas/Preparation/UpdateBankStatementsAnalysisCollection.mutation.gql'

import IBankStatementsAnalysis from '@/types/BankStatementsAnalysis.interface'
import IBankStatementsAnalysisInput from '@/types/schemas/Preparation/BankStatementsAnalysisInput.interface'
import IBankStatementsAnalysisQuery from '@/types/schemas/Preparation/BankStatementsAnalysisQuery.interface'
import IUpdateBankStatementsAnalysisCollectionMutation from '@/types/schemas/Preparation/UpdateBankStatementsAnalysisCollectionMutation.interface'

const getProjectId = (): string | null => {
  const store = useStore()

  return store.projectId
}

const getBankStatementsAnalysis = async (): Promise<IBankStatementsAnalysis[]> => {
  const {
    data: { bankStatementsAnalysisCollection },
  } = await useQuery<IBankStatementsAnalysisQuery>({
    query: BankStatementsAnalysisQuery,
    variables: {
      projectId: getProjectId(),
    },
  })

  return bankStatementsAnalysisCollection
}

const triggerBankStatementsAnalysis = async (id: string): Promise<void> => {
  await useQuery({
    query: TriggerBankStatementsAnalysisQuery,
    variables: {
      id,
    },
  })
}

const updateBankStatementsAnalysisCollection = async (
  bankStatementsAnalysisCollection: IBankStatementsAnalysisInput[],
): Promise<void> => {
  await useMutation<IUpdateBankStatementsAnalysisCollectionMutation>({
    mutation: UpdateBankStatementsAnalysisCollectionMutation,
    variables: {
      bankStatementsAnalysisCollection,
    },
  })
}

export const useBankStatementService = () => ({
  getBankStatementsAnalysis,
  triggerBankStatementsAnalysis,
  updateBankStatementsAnalysisCollection,
})
