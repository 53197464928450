declare global {
  interface Window {
    StonlyWidget: (action: string, data: object) => void
  }
}

const setAdditionalInfo = (additionalInfo: Record<string, string>): void => {
  window.StonlyWidget('sendData', {
    contactForm: { additionalInfo },
  })
}

const setUserEmail = (email: string): void => {
  window.StonlyWidget('sendData', {
    contactForm: { email },
  })
}

export const useStonlyHelper = () => ({
  setAdditionalInfo,
  setUserEmail,
})
