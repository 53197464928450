import * as Sentry from '@sentry/vue'
import router from '@/router'
import { Vue } from '@sentry/vue/types/types'

export const initSentry = (app: Vue) => {
  Sentry.init({
    app,
    dsn: 'https://418b3ed32e4c4b75a95d71741f2bbde7@o1073095.ingest.sentry.io/4504236997083136',
    integrations: [
      Sentry.browserTracingIntegration({
        router,
      }),
      new Sentry.Replay({
        maskAllText: true,
        blockAllMedia: true,
      }),
    ],
    trackComponents: true,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,
    tracePropagationTargets: ['https://zou.finspot.co', /^\//],
    // Session replays
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })
}
