import { IFinancingValid, IFinancingError } from './Financing.interface'

export enum EFinancingState {
  Error = 'error',
  Valid = 'valid',
}

// Use financing.state === EFinancingState.Error to check if financing has error
export type TFinancingError = IFinancingError & {
  state: EFinancingState.Error
}

// Use financing.state === EFinancingState.Valid to check if financing has no error
export type TFinancingValid = IFinancingValid & {
  state: EFinancingState.Valid
}

type TFinancing = TFinancingValid | TFinancingError

export default TFinancing
