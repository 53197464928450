import { useStore } from '@/config/composables/store'

const getActiveRequestId = (): string => {
  const store = useStore()

  return store.activeRequestId!
}

const getProjectId = (): string => {
  const store = useStore()

  return store.projectId!
}

export const useServiceHelper = () => ({
  getActiveRequestId,
  getProjectId,
})
